var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"user-select":"none"}},[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[(_vm.pageCount > _vm.pagesToShow)?_c('v-btn',{attrs:{"disabled":_vm.selectedPage === 1,"text":"","small":"","icon":""},on:{"click":function($event){return _vm.$emit('selected', 1)}}},[_c('v-icon',{attrs:{"color":"kajot-disabled"}},[_vm._v("mdi-page-first")])],1):_vm._e(),(_vm.pageCount > 1)?_c('v-btn',{attrs:{"disabled":_vm.selectedPage === 1,"text":"","small":"","icon":""},on:{"click":function($event){return _vm.$emit('selected', _vm.selectedPage - 1)}}},[_c('v-icon',{attrs:{"color":"kajot-disabled"}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),_c('div',{staticClass:"mx-1"},_vm._l((_vm.shownPages),function(page){return _c('v-hover',{key:`${page}-clicker`,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-avatar',{directives:[{name:"ripple",rawName:"v-ripple"}],class:{ 'mr-2': page !== _vm.pageCount },staticStyle:{"cursor":"pointer"},attrs:{"size":"30","color":`${
						hover
							? _vm.color
							: page === _vm.selectedPage
							? _vm.color
							: 'secondary lighten-1'
					}`},on:{"click":function($event){return _vm.$emit('selected', page)}}},[_vm._v(" "+_vm._s(page)+" ")])]}}],null,true)})}),1),(_vm.pageCount > 1)?_c('v-btn',{attrs:{"disabled":_vm.selectedPage === _vm.pageCount,"text":"","small":"","icon":""},on:{"click":function($event){return _vm.$emit('selected', _vm.selectedPage + 1)}}},[_c('v-icon',{attrs:{"color":"kajot-disabled"}},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.pageCount > _vm.pagesToShow)?_c('v-btn',{attrs:{"disabled":_vm.selectedPage === _vm.pageCount,"text":"","small":"","icon":""},on:{"click":function($event){return _vm.$emit('selected', _vm.pageCount)}}},[_c('v-icon',{attrs:{"color":"kajot-disabled"}},[_vm._v("mdi-page-last")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }